@import url(https://fonts.googleapis.com/css?family=Droid+Sans:400,700);
body {
  margin: 0;
  padding: 0;
  font-family: 'Sedgwick Ave', cursive;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.76px;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
}

.app-container {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

.App-header {
  width: 100%;
  position: fixed;
  z-index: 1;
}

*:focus {
  outline: none;
}

.no-padding {
  padding: 0px 0px 0px 0px !important;
}

.slideInner___2mfX9 {
  position: unset !important;
  width: 330px !important;
}

@-webkit-keyframes intro {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes intro {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

h1 sub {
  display: block;
  font-size: 0.3em;
  letter-spacing: 0;
  line-height: 0.8em;
}

@-webkit-keyframes logo {
  0% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.1);
    opacity: 0;
  }
}

@keyframes logo {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(0.1);
    opacity: 0;
  }
}

/* animation */
@-webkit-keyframes scroll {
  0% {
    top: 100%;
  }
  100% {
    top: -650%;
  }
}

@keyframes scroll {
  0% {
    top: 100%;
  }
  100% {
    top: -650%;
  }
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

