body {
  margin: 0;
  padding: 0;
  font-family: 'Sedgwick Ave', cursive;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.76px;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121212;
}

.app-container {
  height: 100%;
}

html {
  scroll-behavior: smooth;
}

.App-header {
  width: 100%;
  position: fixed;
  z-index: 1;
}

*:focus {
  outline: none;
}

.no-padding {
  padding: 0px 0px 0px 0px !important;
}

.slideInner___2mfX9 {
  position: unset !important;
  width: 330px !important;
}
