@import url(https://fonts.googleapis.com/css?family=Droid+Sans:400,700);

@-webkit-keyframes intro {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes intro {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes intro {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes intro {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes intro {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

h1 sub {
  display: block;
  font-size: 0.3em;
  letter-spacing: 0;
  line-height: 0.8em;
}

@-webkit-keyframes logo {
  0% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.1);
    opacity: 0;
  }
}

@-moz-keyframes logo {
  0% {
    -moz-transform: scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    -moz-transform: scale(0.1);
    opacity: 0;
  }
}

@-ms-keyframes logo {
  0% {
    -ms-transform: scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    -ms-transform: scale(0.1);
    opacity: 0;
  }
}

@-o-keyframes logo {
  0% {
    -o-transform: scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    -o-transform: scale(0.1);
    opacity: 0;
  }
}

@keyframes logo {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(0.1);
    opacity: 0;
  }
}

/* animation */
@-webkit-keyframes scroll {
  0% {
    top: 100%;
  }
  100% {
    top: -650%;
  }
}

@-moz-keyframes scroll {
  0% {
    top: 100%;
  }
  100% {
    top: -650%;
  }
}

@-ms-keyframes scroll {
  0% {
    top: 100%;
  }
  100% {
    top: -650%;
  }
}

@-o-keyframes scroll {
  0% {
    top: 100%;
  }
  100% {
    top: -650%;
  }
}

@keyframes scroll {
  0% {
    top: 100%;
  }
  100% {
    top: -650%;
  }
}
